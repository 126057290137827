$(document).ready(function() {
    'use strict';

    colio_run();

    /* Mobile Menu
    ================================================================================ */
    $('.menu-toggle').click(function() {
        $(".nav").toggleClass("mobile-nav");
        $(this).toggleClass("is-active");
    });

    /* EU-Cookie
    ================================================================================ */
    var eleu = jQuery("#trainmed-eucookie");
    var buttoneu = jQuery("#trainmed-eucookie button");
    if (-1 === document.cookie.indexOf("trainmed-eucookie")) {
        setTimeout(function() { eleu.slideDown("slow"); }, 1000);
    }
    buttoneu.on("click", function() {
        var date = new Date;
        date.setDate(date.getDate() + 30), document.cookie = "trainmed-eucookie=" + escape("hide") + ";path=/;expires=" + date.toGMTString();
        eleu.slideUp("slow");
    });

    /* FactSlider Startseite
    ================================================================================ */
    var slider = $('.FactSlider').bxSlider({
        mode: 'fade',
        auto: true
    });
    $('.FactSlider li').click(function() {
        slider.goToNextSlide();
        return false;
    });

    /* Accordion
    ================================================================================ */
    $('.accordionHeaderClosed').not('.accordionHeaderOpen').next('.accordion').hide();
    $('.accordionHeaderClosed').click(function() {
        if ($(this).hasClass('accordionHeaderOpen')) {
            $(this).next('.accordion').slideToggle('slow');
            $(this).removeClass('accordionHeaderOpen');
        } else {
            $(this).next('.accordion').slideToggle('slow');
            $(this).addClass('accordionHeaderOpen');
        };
        return false;
    });

    /* Übernahme des Downloadtextes in title-Attribut
    ================================================================================ */
    $('a.serviceItem').attr('title', function() {
        return $(this).text()
    })

    /* Tab-Sprungmarken für externen Aufruf
    ================================================== */
    /*  var active = 1;
      if (window.location.search.length > 0) {  var active = parseInt(window.location.search.substr(1)); }    
      if ((active != 1)&&(active != 2)) { active = 1; }
        $(".kontakt .content-wrap section").removeClass("content-current");    
        $(".kontakt .tab-nav ul li").removeClass("tab-current");                
        $("#section-"+active).addClass("content-current");
        $(".kontakt .tab-nav ul li").eq(active-1).addClass("tab-current");*/

    if (window.location.hash != '') {
        var hash = window.location.hash;
        if ($(hash).length > 0) {
            if ($(hash).hasClass('tab-section')) {
                // update content
                $('.tab-section.content-current').removeClass('content-current');
                $(hash).addClass('content-current');

                $('.tab-nav li.tab-current').removeClass('tab-current');
                $('.tab-nav li a[href=' + hash + ']').parent().addClass('tab-current');
            }
        }
    }

    //Qualifikationen
    /*$(".moreInfosButton").click(function(){ // trigger 
      $(this).next("ul").slideToggle("fast"); // blendet beim Klick auf "dt" die nächste "dd" ein. 
      $(this).children("a").toggleClass("closed open"); // wechselt beim Klick auf "dt" die Klasse des enthaltenen a-Tags von "closed" zu "open". 
    });*/

    //Service
    //var $serviceRow = $(".service .row a.encrypted");
    //$serviceRow.replaceWith(function () {
    //  return $('<span/>', {
    //    class: 'encrypted',
    //    title: 'Diese Datei steht nur Schulungsteilnehmern zur Verfügung. Bitte loggen Sie sich ein.',
    //    html: this.innerHTML
    //  });
    //});

    //Animate.css
    /*$('.main, .footer, .PageTitle').css('opacity:0');
    $('.PageTitle').show().addClass('animated fadeIn');
    $('.main').show().addClass('animated fadeIn');
    $('.footer').show().addClass('animated fadeIn');
    $('.PageTitle-maskText').show().addClass('animated fadeInLeft');
    $('.PageTitle-text').show().addClass('animated fadeIn');*/

    /* Order form
  ================================================== */
    $("#order-form").validate({
        ignore: [],
        rules: {
            firma: {
                required: true,
            },
            companyContact: {
                required: true,
            },
            companyPhone: {
                required: true,
            },
            companyMail: {
                required: true,
                email: true
            },
            date: {
                required: true,

            },
            host: {
                required: true,
            },
            address: {
                required: true,
            },
            city: {
                required: true,
            },
            contactName: {
                required: true,
            },
            contactPhone: {
                required: true,
            },
            contactAvailability: {
                required: true,
            },
            contactParticipiant: {
                required: true,
            },
            contactField: {
                required: true,
            },
            disclaimer: {
                required: true,
                minlength: 1
            }
        },
        showErrors: function(errorMap, errorList) {
            $(".contactErrorMessage").html("Beim Absenden des Formulars sind Fehler aufgetreten. Bitte überprüfen Sie die Eingabe in den rot markierten Feldern.");
            this.defaultShowErrors();
            if (this.numberOfInvalids() > 0) {
                $(".contactErrorMessage").show();
            } else {
                $(".contactErrorMessage").hide();
            }
        },
        errorPlacement: function() {
            return false; // <- kill default error labels
        }
    });

    /* Video Overlay
    ================================================================================ */
    $('#play-video').on('click', function(e) {
        e.preventDefault();
        $('#video-overlay').addClass('open');
        $('.video-overlay .fillWidth .layerVideo').append('<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/nNhNRYXg4Lo?rel=0&amp;controls=0&amp;autoplay=1&amp;showinfo=0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
        $('body').addClass('layered');
    });

    $('.video-overlay-close').on('click', function(e) {
        e.preventDefault();
        close_video();
    });

    /* Magnific Popup
    ================================================================================ */
    $('.inline-popups').magnificPopup({
        delegate: 'a',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });

});

$(window).on("scroll touchmove", function() {
    $('.navbar').toggleClass('fixed-nav', $(document).scrollTop() > 0);
});
$(window).on("scroll touchmove", function() {
    $('.ClientArea .button').toggleClass('button-cta', $(document).scrollTop() > 0);
});

/* colio plugin
 ================================================== */
var colio_run = function() {
    $('.angebote .grid').colio({
        id: 'trainmed',
        theme: 'black',
        placement: 'after',
        beforeLastRow: true,
        contentFadeIn: 0,
        onContent: function(content) {
            // init "fancybox" plugin
            $('.fancybox', content).fancybox({
                nextEffect: 'fade', // elastic, fade or none. default: elastic
                prevEffect: 'fade',
                helpers: {
                    title: {
                        type: 'outside' // float, inside, outside
                    },
                    overlay: {
                        speedIn: 0,
                        speedOut: 0,
                        opacity: 0.85,
                        css: {
                            cursor: 'pointer',
                            'background-color': 'rgba(0, 0, 0, 0.85)' //Browsers who don`t support rgba will fall back to default color value defined at CSS file
                        },
                        closeClick: true
                    },
                    buttons: {
                        tpl: '<div id="fancybox-buttons"><ul><li><a class="btnPrev linkedImage" title="Previous" href="javascript:$.fancybox.prev();">Previous</a></li><li><a class="btnPlay linkedImage" title="Slideshow" href="javascript:$.fancybox.play();;">Play</a></li><li><a class="btnNext linkedImage" title="Next" href="javascript:$.fancybox.next();">Next</a></li><li><a class="btnToggle linkedImage" title="Toggle size" href="javascript:$.fancybox.toggle();">Toggle</a></li><li><a class="btnClose linkedImage" title="Close" href="javascript:$.fancybox.close();">Close</a></li></ul></div>'
                    }
                },
                beforeShow: function() {
                    // Get rich titles
                    var currentElement = this.element;
                    var next = $(currentElement).next();
                    if (next.length && next.hasClass('caption')) this.title = next.html();
                    //var currentIndex = this.index;
                }
            });
        }
    });
};

/* Close Video Overlay
================================================================================ */
function close_video() {
    $('.video-overlay.open').removeClass('open');
    //$('.video-overlay video').get(0).pause();
    //$('.video-overlay video').remove();
    $('.video-overlay iframe').remove();
    $('.video-overlay video').remove();
    $('body').removeClass('layered');
};

$(document).keyup(function(e) {
    if (e.keyCode === 27) { close_video(); }
});